import React from "react"
import { inject, observer } from "mobx-react"
import styles from "../components/styles"
import colors from "./colors"

class Medium extends React.Component {
    
    componentDidMount() {
        this.initScript()
    }

    initScript = () => {
        const script = document.createElement("script")
        script.src = "https://medium-widget.pixelpoint.io/widget.js"
        script.async = true
        script.onload = () => this.scriptLoaded()
        document.body.appendChild(script)
    }

    scriptLoaded = async () => {
        const params = {
          renderTo: "#medium-widget",
          params: {
            resource: "https://medium.com/liquiditeam",
            postsPerLine: this.props.store.device==='desktop'?3 : 1,
            limit: 3,
            picture: "big",
            fields: ["description", "publishAt"],
            ratio: "landscape",
          },
        }
        try {
             if (typeof window !== 'undefined') window.MediumWidget.Init(params)
        }
        catch (e) {
        }
      }

    render(){
      
      let selectedStyles =
      this.props.store.device === "mobile" ? styles.mobile : styles.desktop
        if (typeof window !== 'undefined' && window.MediumWidget) window.MediumWidget.unmount()
        if (typeof window !== 'undefined') this.scriptLoaded()
        return(
        <div>
          <div id="medium-widget" ></div>
          <div style={{display:'flex',justifyContent:'center', alignItems:'center'}}>
          <div
          style={selectedStyles.index.upperSection.cta}
          >
            <div 
            onClick={() =>window ? window.open('https://medium.com/liquiditeam', '_blank'): null}
            style={{marginBottom: 0,
    color: colors.white,
    fontSize: 18,
    fontWeight: "bold",}}>{this.props.store.language==='en'?'Read More': 'Alle Artikel'}</div>
          </div>
          </div>
        </div>
        )
    }
}

export default inject("store")(observer(Medium))


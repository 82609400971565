import React from "react"
import styles from '../components/styles'
import ReactMarkdown from "react-markdown"
const TeamMember = (props) => {
    let selectedStyles = props.device==='mobile'? styles.mobile:styles.desktop
    if (props.device==='mobile') {
        return(
            <div style={selectedStyles.teamMember.container}>
                <div style={selectedStyles.teamMember.innerContainer}>
                    <div style={selectedStyles.teamMember.imgContainer}>
                        <img src={props.teamMember.photo} style={selectedStyles.teamMember.img} alt={props.teamMember.name}/>
                    </div>
                    <div style={selectedStyles.teamMember.infoContainer}>
                        <h3 style={selectedStyles.teamMember.name}>{props.teamMember.name}</h3>
                        <div style={selectedStyles.teamMember.position}><ReactMarkdown escapeHtml={false} parserOptions={{ commonmark: true }} style={{}} source={props.teamMember.position}/></div>
                        <div style={{display:'flex', justifyContent:'space-evenly', width:'75%', marginTop:'-1rem'}}>
                            {(!props.teamMember.linkedIn||props.teamMember.linkedIn==='')?null:<a href={props.teamMember.linkedIn}><img src='/assets/linkedin.png' style={{width:'30px', height:'30px'}}  alt='link to linked in'/></a>}
                            {(!props.teamMember.twitter||props.teamMember.twitter==='')?null:<a href={props.teamMember.twitter}><img src='/assets/twitter.png' style={{width:'30px', height:'30px'}} href={props.teamMember.twitter} alt='link to twitter'/></a>}
                            {(!props.teamMember.medium||props.teamMember.medium==='')?null:<a href={props.teamMember.medium}> <img src='/assets/medium.png' style={{width:'30px', height:'30px'}} href={props.teamMember.medium} alt='medium'/></a>}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    return(
    <div style={selectedStyles.teamMember.container}>
        <div style={selectedStyles.teamMember.innerContainer}>
            <img src={props.teamMember.photo} style={selectedStyles.teamMember.img} alt={props.teamMember.name}/>
            <h3 style={selectedStyles.teamMember.name}>{props.teamMember.name}</h3>
            <div style={selectedStyles.teamMember.position}><ReactMarkdown escapeHtml={false} parserOptions={{ commonmark: true }} source={props.teamMember.position}/></div>
            <div style={{display:'flex', justifyContent:'space-evenly', width:'75%'}}>
                {(!props.teamMember.linkedIn||props.teamMember.linkedIn==='')?null:<a href={props.teamMember.linkedIn} style={{display:'flex', justifyContent:'flex-end'}}><img src='/assets/linkedin.png' style={{width:'30px', height:'30px'}}  alt='link to linked in'/></a>}
                {(!props.teamMember.twitter||props.teamMember.twitter==='')?null:<a href={props.teamMember.twitter} style={{display:'flex', justifyContent:'flex-end'}}><img src='/assets/twitter.png' style={{width:'30px', height:'30px'}} href={props.teamMember.twitter} alt='link to twitter'/></a>}
                {(!props.teamMember.medium||props.teamMember.medium==='')?null:<a href={props.teamMember.medium} style={{display:'flex', justifyContent:'flex-end'}}> <img src='/assets/medium.png' style={{width:'30px', height:'30px'}} href={props.teamMember.medium} alt='medium'/></a>}
            </div>
        </div>
    </div>
)}
export default TeamMember
import React from "react"

import addToMailchimp from "gatsby-plugin-mailchimp"
import { Link } from "gatsby"
import { inject, observer } from "mobx-react"
import Helmet from "react-helmet"
import ReactMarkdown from "react-markdown"

import Layout from "../components/layout"
import landingPageData_de from "../../content/de_landingPage.json"
import landingPageData_en from "../../content/en_landingPage.json"
import TeamMember from "../components/teamMember"
import Medium from "../components/medium"
import styles from "../components/styles"
import colors from "../components/colors"
import seo from "../../content/seo.json"
import Newsletter from '../components/Newsletter'
import ContactBanner from '../components/ContactBanner'


class IndexPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      device: "desktop",
      email: "",
    }
    //if (typeof window !== `undefined`) window.navigator.language.includes("de")?this.props.store.switchLanguage('de'):this.props.store.switchLanguage('en')

  }
  async componentDidMount() {
    /*const script = document.createElement("script")
    script.src = "https://medium-widget.pixelpoint.io/widget.js"
    script.async = true
    script.onload = () => this.scriptLoaded()

    document.body.appendChild(script)
*/
    this.handleResize()
    //if (typeof window !== `undefined`) window.navigator.language.includes("de")?this.props.store.switchLanguage('de'):this.props.store.switchLanguage('en')
    if (typeof window !=='undefined') window.addEventListener("resize", this.handleResize)
  }

  /*scriptLoaded = () => {
    const params = {
      renderTo: "#medium-widget",
      params: {
        resource: "https://medium.com/liquiditeam",
        postsPerLine: this.state.device==='desktop'?3 : 1,
        limit: 3,
        picture: "big",
        fields: ["description", "publishAt"],
        ratio: "landscape",
      },
    }
    if (window.MediumWidget) window.MediumWidget.Init(params)
  }*/

  componentWillUnmount() {
    if (typeof window !=='undefined') window.removeEventListener("resize", this.handleResize)
  }

  handleResize = () => {
    if (
      document.documentElement.clientWidth > 599 &&
      this.state.device !== "desktop"
    ) {
      this.setState({ device: "desktop" }/*, () => {
        if (window.MediumWidget) {
          window.MediumWidget.unmount()
          this.scriptLoaded()
        }
      }*/)
    }
    if (
      document.documentElement.clientWidth < 600 &&
      this.state.device !== "mobile"
    ) {
      this.setState({ device: "mobile" } /*,() => {
        if (window.MediumWidget) {
          window.MediumWidget.unmount()
          this.scriptLoaded()
        }
      }*/)
    }
  }

  handleSubscription = async event => {
    event.preventDefault()
    try {
      let res = await addToMailchimp(this.state.email)

      if (res.result === "success") {
        alert(res.msg)
      } else {
        alert(res.msg)
      }
    } catch (err) {
      console.log("error")
      console.log(err)
    }
  }
  render() {
    let selectedStyles =
      this.props.store.device === "mobile" ? styles.mobile : styles.desktop
    let landingPageData =
      this.props.store.language === "en"
        ? landingPageData_en
        : landingPageData_de

    return (
      <Layout data={landingPageData} device={this.state.device}>
        <Helmet>
          <title>{seo.title}</title>
          <meta name="description" content={seo.description} />
          <meta name="keywords" content={seo.keywords} />
          <meta charSet="utf-8" />
          <html lang={this.props.store.language} />
        </Helmet>
        <div style={{ backgroundColor: colors.lightgrey, position:'relative', zIndex: 1 }}>
          <img
            src={landingPageData.upperImage}
            style={selectedStyles.index.upperSection.img}
            alt="visitors of football stadium"
          />
          <div style={selectedStyles.index.upperSection.container}>
            <div style={selectedStyles.index.upperSection.polygon}>
              <div
                style={selectedStyles.index.upperSection.polygonTextContainer}
              >
                <h1 style={selectedStyles.index.upperSection.heading}>
                  {landingPageData.upperHeading}
                </h1>
                <div style={selectedStyles.index.upperSection.text}>
                  <ReactMarkdown escapeHtml={false} parserOptions={{ commonmark: true }} source={landingPageData.upperText} />
                </div>
                <Link
                  to="/contact"
                  style={selectedStyles.index.upperSection.cta}
                >
                
                  {landingPageData.ctaText}
                  

                </Link>
              </div>
            </div>
          </div>

          <div style={selectedStyles.index.upperSection.goldFeatureContainer}>
            <div style={selectedStyles.index.upperSection.goldFeature}></div>
          </div>
          <div style={selectedStyles.index.middleSection.container}>
          {this.props.store.device==='mobile' && <div style={{display:'flex', justifyContent:'center', padding:'50px 0px 0px', width:'100%'}}>
                <Link
                  to="/insights"
                  style={selectedStyles.index.upperSection.cta}
                >
                  {this.props.store.language==='en'?'Learn More':'Erfahre Mehr'}
                </Link>
                </div>}
            <div style={selectedStyles.index.middleSection.imgContainer}>
              <img
                src={landingPageData.middleImage}
                style={selectedStyles.index.middleSection.img}
                alt="some graphic"
              />
            </div>
            <div style={selectedStyles.index.middleSection.textContainer}>
              <div
                style={selectedStyles.index.middleSection.innerTextContainer}
              >
                <h3 style={selectedStyles.index.middleSection.heading}>
                  {landingPageData.middleHeading}
                </h3>
                <h2 style={selectedStyles.index.middleSection.subHeading}>
                  {landingPageData.middleSubHeading}
                </h2>
                <div style={selectedStyles.index.middleSection.text}>
                  <ReactMarkdown escapeHtml={false} parserOptions={{ commonmark: true }} source={landingPageData.middleText} />
                </div>
                {this.props.store.device==='desktop' && <div style={{padding:'50px 0px 0px'}}>
                <Link
                  to="/insights"
                  style={selectedStyles.index.upperSection.cta}
                >
                  {this.props.store.language==='en'?'Learn More':'Erfahre Mehr'}
                </Link>
                </div>
                }
                
              </div>
            </div>
          </div>
          <div style={selectedStyles.index.lowerSection.upperBackground}></div>
          <div
            style={{
              height: "9.9vw",
              marginTop: "-10vw",
              width: "100%",
              backgroundColor: colors.darkblue,
              clipPath:
                "polygon(0% 100%, 7% 50%, 93% 50%, 100% -1%, 0% -1%)",
              WebkitClipPath:
                "polygon(0% 100%, 7% 50%, 93% 50%, 100% -1%, 0% -1%)",
            }}
          ></div>
          <div
            style={{
              //height: this.state.device==='mobile'? '140vh': '90vh',
              //marginTop: this.state.device==='mobile'? '-140vh': '-90vh',
              backgroundColor: colors.lightgrey,
              //WebkitClipPath:this.state.path,
              display: "flex",
              flexDirection: this.props.store.device === "mobile" ? "column" : "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div style={selectedStyles.index.lowerSection.textContainer}>
              <div style={selectedStyles.index.lowerSection.innerTextContainer}>
                <h3 style={selectedStyles.index.lowerSection.heading}>
                  {landingPageData.lowerHeading}
                </h3>
                <h2 style={selectedStyles.index.lowerSection.subHeading}>
                  {landingPageData.lowerSubHeading1}
                </h2>
                <div style={selectedStyles.index.lowerSection.text}>
                  <ReactMarkdown escapeHtml={false} parserOptions={{ commonmark: true }} source={landingPageData.lowerText1} />
                </div>
                <h2 style={selectedStyles.index.lowerSection.subHeading}>
                  {landingPageData.lowerSubHeading2}
                </h2>
                <div style={selectedStyles.index.lowerSection.text}>
                  <ReactMarkdown escapeHtml={false} parserOptions={{ commonmark: true }} source={landingPageData.lowerText2} />
                </div>
              </div>
              
            </div>
            <div style={selectedStyles.index.lowerSection.imgContainer}>
            <Link
            to='/lt-fan-platform/early-adopters-club'
            >
              <img
                src={landingPageData.lowerImage}
                style={selectedStyles.index.lowerSection.img}
                alt="some graphic"
              />
              </Link>
            </div>
            
          </div>
          <div
            style={{
              height: "10vw",
              width: "100%",
              backgroundColor: colors.white,
              clipPath:
                "polygon(0% 100%, 7% 50%, 93% 50%, 100% 0%, 101% 100%)",
              WebkitClipPath:
                "polygon(0% 100%, 7% 50%, 93% 50%, 100% 0%, 101% 100%)",
            }}
          ></div>
          <div style={selectedStyles.index.lowerSection.lowerBackground}></div>
          
          <div style={{
          padding: "0vw 5vw",
          //height: "130vh",
          backgroundColor: colors.white,
        }}>
            <h2 style={{
          padding: "3vw 0vw 0vw 5vw",
          fontSize: "1.5625rem",
          color: colors.black
        }}>
              {this.props.store.language === "en" ? "Current Thinking" : "Aktuelle Artikel"}
            </h2>
            <Medium/>
          </div>
          
          
          <div
            style={{
              height: "10vw",
              width: "100%",
              backgroundColor: colors.grey,
              clipPath:
                "polygon(0% 101%, 7% 50%, 93% 50%, 100% 0%, 101% 101%)",
              WebkitClipPath:
                "polygon(0% 101%, 7% 50%, 93% 50%, 100% 0%, 101% 101%)",
            }}
          ></div>
          <div style={{
          height: "10vw",
          marginTop:'-10vw',
          backgroundColor: colors.white,
          zIndex: "-1",
          position: "relative",
        }}></div>

          

          <div style={selectedStyles.index.teamSection.container}>
            <h2 style={selectedStyles.index.teamSection.heading}>
              {this.props.store.language === "en" ? "Our Team" : "Unser Team"}
            </h2>
            <div style={selectedStyles.index.teamSection.memberContainer}>
              {landingPageData.team.map((teamMember, i) => (
            <TeamMember key={i} teamMember={teamMember} device={this.props.store.device} />
          ))}
            </div>
          </div>
          
          <ContactBanner backgroundColor={colors.grey}/>
          <Newsletter/>
        </div>
      </Layout>
    )
  }
}

export default inject("store")(observer(IndexPage))
